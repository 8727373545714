<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="700px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:400px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                        <v-form id="form_registration_number" ref="form" v-model="valid" lazy-validation>
                            
                            <v-container grid-list-lg>
                                <v-layout row wrap>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000">Lokacija:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined" class="font-weight-bold"><v-chip label dark small color="#FFA014">{{ deviceName }}</v-chip></span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Registrska številka:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined && registrationNumber !==''" class="font-weight-bold"><v-chip label dark small color="#329ef4">{{ registrationNumber}}</v-chip></span>
                                        <span v-else>/</span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Datum prihoda:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined" class="font-weight-bold">{{ arrivalDate}}</span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Čas prihoda:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined" class="font-weight-bold">{{ arrivalTime}}</span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Trajanje parkiranja:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined" class="font-weight-bold">{{ duration}}</span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>

                                    <v-flex xs12 sm12 md2 lg12>
                                        <v-switch class="ti pl-1" v-model="truck" :label="`Tovorno vozilo: ${truckLabel}`"></v-switch>
                                    </v-flex>

                                    <!--<v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Cena:</span>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <span v-if="item !== undefined" class="font-weight-bold">{{ price_formatted }} €</span>
                                    </v-flex>
                                    <div style="min-height:30px;"></div>-->

                                    <!--
                                    <v-flex xs12 sm12 md2 lg12>
                                        <v-switch :disabled="gratisDisabled" @change="gratisValidation()" class="ti pl-1" v-model="gratis" :label="`Brezplačna validacija: ${checkboxGratisValidationLabel}`"></v-switch>
                                    </v-flex>
                                    <div style="min-height:30px;" v-if="gratis"></div>-->
                                    <v-flex xs12 sm12 md12 lg12>
                                        <v-textarea
                                        outlined
                                        name="input-7-4"
                                        label="Opombe / opis situacije"
                                        v-model="note"
                                        counter
                                        required
                                        :readonly="noteReadOnly"
                                        clearable
                                        ></v-textarea>
                                    </v-flex>

                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Validiraj</v-btn>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'add-registraton-number-dialog',
    
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: false,
        item: null,
        dialog: false,
        loading: false,
        disableSubmit: false,
        note: null,
        formTitle: "",
        cancelText: 'Prekliči',
        registration_number: null,
        event_timestamp: null,
        gratis: false,

        gratisDisabled: false,
        noteReadOnly: false,
        price: null,
        truck: false,
        price_formatted: null,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],
        currentDate: null,

        duration: null,

        note_rules: [v => !!v || 'Polje za vpis opombe je obvezno'],

        
    }),

    watch: {
        item: {
            deep: true,
            immediate: true,
            handler(value) {
                //window.console.log(value)
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        registrationNumber() {
            if(this.item !== null && this.item !== undefined && this.item.registration_plate_number !== undefined) {
                return this.item.registration_plate_number
            }

            return ""
        },

        arrivalDate() {
            if(this.item !== null && this.item !== undefined && this.item.event_timestamp_formatted !== undefined) {
                return this.item.event_timestamp_formatted.split(" ")[0];
            }

            return ""
        },

        arrivalTime() {
            if(this.item !== null && this.item !== undefined && this.item.event_timestamp_formatted !== undefined) {
                return this.item.event_timestamp_formatted.split(" ")[1];
            }

            return ""
        },

        durationStr() {
            if(this.item !== null && this.item !== undefined && this.item.duration_str !== undefined) {
                return this.item.duration_str
            }

            return ""
        },

        deviceName() {
            if(this.item !== null && this.item.device_name !== undefined) {
                return this.item.device_name
            }
            return ""
        },

        priceFormatted() {
            return ""
        },

        checkboxGratisValidationLabel() {
            if(this.gratis) {
                return 'DA'
            }

            return 'NE'
        },

        truckLabel() {
            if(this.truck) {
                return 'DA'
            }

            return 'NE'
        }


    },

    methods: {
        submitForm() {
            var vm = this
            if(this.$refs.form.validate()) {

                //window.console.log(vm.registration_number_1)
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                vm.$store.dispatch('ADD_REGISTRATION_NUMBER', {
                    event_id: vm.item.id,
                    event_timestamp: vm.item.event_timestamp,
                    registration_number: vm.item.registration_plate_number,
                    note: vm.note,
                    price: vm.price,
                    truck: vm.truck,
                    gratis: (vm.gratis == 1 || vm.gratis == true) ? 1 : 0
                })
                .then(response => {
                    //window.console.log(response)
                    vm.backendMessages.push("Registrska številka " + vm.item.registration_plate_number + " je uspešno validirana.");
                    vm.disableSubmit = true
                    vm.cancelText = "Zaključi"

                })
                .catch(error => {
                    console.log('ADD_REGISTRATION_NUMBER error')
                    console.log(error)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Pri validiranju registrske številke ' + vm.item.registration_plate_number + ' je prišlo do napake. Prosimo poskusite ponovno.')
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false
                    },600)
                })
            } else {
                setTimeout(() => {
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }, 200)
            }

        },

        cancel() {
            this.clearForm()
            this.item = null
            this.dialog = false
            this.disableSubmit = false
            this.price = 0.0;
            this.gratis = false;
            this.truck = false;
            this.note = ""
            EventBus.$emit('refresh_dt_events', null)
        },

        clearForm() {
            this.clearBackendMessages()
            this.note = null
            this.registration_number = null
            this.event_timestamp = null
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        setFormTitle() {
            //this.formTitle = this.item.device_name + ": "  + this.item.registration_plate_number.toUpperCase() + " - " + this.item.event_timestamp_formatted;
            //this.formTitle = this.item.device_name
            this.formTitle = "Validacija registrske številke"

        },

        getDurationTime(eventTimestamp) {
            this.$store.dispatch("DATE_TIME_DURATION", {
                start_date_time: eventTimestamp
            })
            .then(response => {
                window.console.log("Response:")
                //window.console.log(response.data)   

                this.duration = response.data.duration_for_humans

            })
            .catch(err => {
                window.console.error(err)
            })
        },

        registrationNumberValidation(event){
            this.$store.dispatch("REGISTRATION_NUMBER_VALIDATION", {
                device_id: event.device_id,
                event_timestamp: event.event_timestamp,
                registration_number: event.registration_plate_number
            })
            .then(response => {
                //window.console.log("Response:")
                //window.console.log(response.data)   

                this.duration = response.data.duration
                this.price = response.data.price
                this.price_formatted = response.data.price_formatted

            })
            .catch(err => {
                window.console.error(err)
            })
        },

        gratisValidation() {
            
        }

    },

    created() {
        var vm = this;
        this.currentDate = getCurrentDate()

        EventBus.$on('ADD_REGISTRATION_NUMBER_DIALOG', function(data) {
            //window.console.log(data)
            vm.item = data
            vm.registration_number = vm.item.registration_plate_number
            vm.event_timestamp = vm.item.event_timestamp_formatted
            vm.setFormTitle();
            //vm.getDurationTime(vm.item.event_timestamp)
            vm.registrationNumberValidation(vm.item)
            vm.dialog = true;
        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('ADD_REGISTRATION_NUMBER_DIALOG')
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }
</style>